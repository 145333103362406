import React from 'react';

function Icon({className}) {
    return (<svg className={className} width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'}
                 xmlns={'http://www.w3.org/2000/svg'}>
            <path
                d={'M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM18.408 8.736C18.48 10.92 17.856 12.672 17.04 14.04C16.248 15.384 15.168 16.464 13.872 17.232C12.576 18.024 10.848 18.6 8.808 18.528C6.912 18.48 5.4 17.928 4.176 17.184C4.104 17.136 4.008 17.088 3.96 17.016C6.072 17.256 7.68 16.56 8.832 15.672C7.992 15.648 7.344 15.36 6.864 14.952C6.384 14.568 5.976 14.04 5.76 13.368C6.192 13.464 6.816 13.44 7.2 13.32C6.408 13.152 5.832 12.72 5.376 12.192C4.92 11.688 4.608 10.992 4.608 10.032C4.632 9.984 4.704 10.056 4.752 10.08C5.112 10.248 5.568 10.392 6.072 10.416C5.256 9.84 4.44 8.736 4.68 7.176C4.752 6.72 4.896 6.336 5.088 6C5.304 6.216 5.496 6.456 5.712 6.672C7.2 8.16 9.12 9.24 11.904 9.456C11.52 7.752 12.408 6.48 13.488 5.856C14.088 5.52 14.928 5.352 15.744 5.52C16.488 5.664 17.064 6.048 17.496 6.48C18.312 6.336 18.96 6.024 19.584 5.688C19.296 6.48 18.84 7.104 18.144 7.488C18.816 7.44 19.464 7.2 20.016 6.984C19.608 7.68 19.032 8.232 18.408 8.736Z'}
                fill={'currentcolor'}/>
        </svg>);
}

export default Icon;