import React from 'react';

function Icon({className}) {
    return (<svg className={className} width={'24'} height={'24'} viewBox={'0 0 40 40'} fill={'none'}
                 xmlns={'http://www.w3.org/2000/svg'}>
            <path fillRule={'evenodd'} clipRule={'evenodd'}
                  d={'M40 20C40 17.3824 39.4792 14.7648 38.4776 12.3464C37.476 9.928 35.9932 7.7088 34.142 5.858C32.2912 4.0068 30.072 2.524 27.6536 1.5224C25.2352 0.5208 22.6176 0 20 0C17.3824 0 14.7648 0.5208 12.3464 1.5224C9.928 2.524 7.7088 4.0068 5.858 5.858C4.0068 7.7088 2.524 9.928 1.5224 12.3464C0.5208 14.7648 0 17.3824 0 20C0 22.6176 0.5208 25.2352 1.5224 27.6536C2.524 30.072 4.0068 32.2912 5.858 34.142C7.7088 35.9932 9.928 37.476 12.3464 38.4776C14.7648 39.4792 17.3824 40 20 40C22.6176 40 25.2352 39.4792 27.6536 38.4776C30.072 37.476 32.2912 35.9932 34.142 34.142C35.9932 32.2912 37.476 30.072 38.4776 27.6536C39.4792 25.2352 40 22.6176 40 20Z'}
                  fill={'currentcolor'}/>
            <path
                d={'M17.7932 19.4659V17.3075C16.1352 16.4919 14.9936 14.7855 14.9936 12.8127C14.9936 10.0487 17.2352 7.80715 20 7.80715C23.1044 7.80715 25.346 10.0487 25.346 12.8127C25.346 13.5095 25.2028 14.1731 24.8592 14.9615L23.9764 12.0915L22.2068 12.6447L23.776 18.6175L29.868 16.3763L29.1276 14.6295L26.8556 15.4071C27.2668 14.5155 27.3476 13.8599 27.3476 12.8127C27.3476 8.94235 24.21 5.80475 20 5.80475C16.1296 5.80475 12.992 8.94235 12.992 12.8127C12.992 15.9119 15.0044 18.5411 17.7932 19.4659Z'}
                className={'text-white dark:text-black-1'} fill={'currentcolor'}/>
            <path d={'M18.9664 10.97H21.0336V29.0308H18.9664V10.97Z'} className={'text-white dark:text-black-1'}
                  fill={'currentcolor'}/>
            <path
                d={'M22.2068 22.6932V20.5348C24.9956 21.4596 27.008 24.0888 27.008 27.188C27.008 31.0584 23.8704 34.196 20 34.196C15.79 34.196 12.6524 31.0584 12.6524 27.188C12.6524 26.1408 12.7332 25.4852 13.1444 24.5936L10.8724 25.3712L10.132 23.6248L16.224 21.3832L17.7932 27.356L16.0236 27.9092L15.1408 25.0392C14.7972 25.8276 14.654 26.4912 14.654 27.188C14.654 29.952 16.8956 32.1936 20 32.1936C22.7648 32.1936 25.0064 29.952 25.0064 27.188C25.0064 25.2152 23.8648 23.5092 22.2068 22.6932Z'}
                className={'text-white dark:text-black-1'} fill={'currentcolor'}/>
        </svg>);
}

export default Icon;