import React from 'react';

function Icon({className}) {
    return (<svg className={className} width={'24'} height={'24'} viewBox={'0 0 24 24'} fill={'none'}
                 xmlns={'http://www.w3.org/2000/svg'}>
            <path fillRule={'evenodd'} clipRule={'evenodd'}
                  d={'M0 12C0 5.376 5.376 0 12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C5.376 24 0 18.624 0 12ZM19.368 15.528C19.44 15.384 19.488 15.216 19.632 14.688C19.776 14.16 19.848 13.248 19.824 12.192C19.824 10.728 19.272 9.552 18.384 8.664C18.528 8.16 18.744 7.056 18.168 5.616C18.168 5.616 18.096 5.592 17.928 5.592C17.52 5.592 16.536 5.736 14.88 6.84C13.992 6.624 13.032 6.504 12.072 6.504C11.04 6.504 9.96 6.648 8.976 6.912C6.6 5.304 5.568 5.64 5.568 5.64C4.896 7.32 5.328 8.592 5.448 8.904C4.632 9.768 4.152 10.872 4.152 12.216C4.152 13.248 4.272 14.16 4.56 14.952C4.56 15.006 4.56 15.006 4.58025 15.033C4.587 15.042 4.596 15.054 4.608 15.072L4.60802 15.0721C4.68001 15.2401 4.75201 15.408 4.824 15.552L4.872 15.648C5.664 17.112 7.248 18.072 10.032 18.384H14.256C17.232 18.072 18.672 17.112 19.32 15.624L19.368 15.528ZM14.6161 10.992C15.3121 10.992 15.9121 11.112 16.3441 11.568C16.8961 12.168 17.2081 12.864 17.2561 13.632C17.2561 17.16 14.9761 17.256 12.1681 17.256C9.36012 17.256 7.08012 16.776 7.08012 13.632C7.08012 12.864 7.39212 12.168 7.94412 11.592C8.61048 10.8906 9.6099 10.9707 10.7833 11.0648C11.2159 11.0995 11.6722 11.136 12.1441 11.136C12.6077 11.136 13.0583 11.0968 13.4889 11.0593C13.8831 11.0249 14.2606 10.992 14.6161 10.992ZM8.32812 13.8C8.32812 14.76 8.80812 15.552 9.38412 15.552C9.96012 15.552 10.4401 14.76 10.4401 13.8C10.4401 12.84 9.96012 12.048 9.38412 12.048C8.80812 12.048 8.32812 12.816 8.32812 13.8ZM13.9921 13.8C13.9921 14.76 14.4721 15.552 15.0481 15.552C15.6481 15.552 16.1041 14.76 16.1041 13.8C16.1041 12.84 15.6241 12.048 15.0481 12.048C14.4721 12.048 13.9921 12.816 13.9921 13.8Z'}
                  fill={'currentcolor'}/>
        </svg>);
}

export default Icon;